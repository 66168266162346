import React, { Suspense, useState } from "react";
import Layout from "./components/Layout/Layout";
import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { css } from "@emotion/react";
import DotLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const mainbuilder = React.lazy(() =>
  import("./containers/mainbuilder/mainbuilder")
);

const App = ({ topbar, islight, loginpage }) => {
  let loading = useState(true);
  let color = useState("#F86F6D");

  return (
    <Suspense
      fallback={
        <DotLoader color={color} loading={loading} css={override} size={150} />
      }
    >
      <div className="App">
        <Layout topbar={topbar} islight={islight} loginpage={loginpage}>
          <Switch>
            <Route path="/" component={mainbuilder} />
          </Switch>
        </Layout>
      </div>
    </Suspense>
  );
};
const mapStatetoProps = (state) => {
  return {
    topbar: state.ui_red.topbar,
    loginpage: state.ui_red.loginpage,
    islight: state.ui_red.islight,
  };
};

export default withRouter(connect(mapStatetoProps)(App));

import React, { Component } from "react";
import Headroom from "react-headroom";
import WhatsIcon from "../../assets/svg/whats.svg";
import MapsIcon from "../../assets/svg/maps.svg";
import facebook from "../../assets/svg/facebook.svg";
import instagram from "../../assets/svg/instagram.svg";
import linkedIn from "../../assets/svg/linkedln.svg";
import { Link, animateScroll as scroll, scrollSpy } from "react-scroll";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  componentDidMount() {
    scrollSpy.update();
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollToBottom() {
    scroll.scrollToBottom();
  }


  openMenu() {
    this.setState({ open: !this.state.open });
  }

  closeMenu() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <Headroom>
        <header id="topnav" className="defaultscroll topnav-light">
          <div className="container">
            <div>
              <Link to="index_1" className="logo">
                Dra. Diana Cruz
              </Link>
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <a
                  href="#toggle"
                  className={`navbar-toggle ${open ? "open" : ""}`}
                  onClick={() => {
                    this.openMenu();
                  }}
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
              </div>
            </div>

            <div id="navigation" style={{ display: open ? "block" : "none" }}>
              <ul className="navigation-menu ">
                <li className="has-submenu">
                  {/* <a href="#home">Inicio</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="home"
                    spy={true}
                    smooth={true}
                    // offset={50}
                    duration={500}
                    // onSetActive={this.handleSetActive}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Inicio
                  </Link>
                </li>
                <li className="has-submenu">
                  {/* <a href="#services">Servicios</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="services"
                    spy={true}
                    smooth={true}
                    duration={500}
                    // delay={1000}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Servicios
                  </Link>
                </li>
                <li className="has-submenu">
                  {/* <a href="#procedures">Procedimientos</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="procedures"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Procedimientos
                  </Link>
                </li>

                <li className="has-submenu">
                  {/* <a href="#education">Formación</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="education"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Formación
                  </Link>
                </li>

                <li className="has-submenu">
                  {/* <a href="#gallery">Galería</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="gallery"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Galería
                  </Link>
                </li>
                <li className="has-submenu">
                  {/* <a href="#information">Información</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="information"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Información
                  </Link>
                </li>

                <li className="has-submenu">
                  {/* <a href="#consultorio">Mi Consultorio</a> */}
                  <Link
                    activeClass="active"
                    className="anchor"
                    to="consultorio"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    Consultorio
                  </Link>
                </li>
                <li className="has-submenu">
                <a
                    className="anchor"
                    href="https://wa.me/5215524987703"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >Citas</a> 
                  
                </li>
              </ul>
              <ul className="navigation-menu menu-right">
                <li className="list-inline-item">
                  <a
                    href=" https://www.facebook.com/FertilidadDianaCruzClavel"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    <img src={facebook} alt="facebook-icon" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/diana-elizabeth-cruz-clavel-197698138/?originalSubdomain=mx"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    <img src={linkedIn} alt="linkedIn-icon" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href=" https://www.instagram.com/ginecologadiana/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    <img src={instagram} alt="instagram-icon" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://wa.me/5215524987703"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    <img src={WhatsIcon} alt="whatsapp-icon" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://goo.gl/maps/1Hu86vR2WZhN9rR18"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    <img src={MapsIcon} alt="maps-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default Header;

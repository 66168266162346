import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import WhatsIcon from "../../assets/svg/whats.svg";
import MapsIcon from "../../assets/svg/maps.svg";
import facebook from "../../assets/svg/facebook.svg";
import instagram from "../../assets/svg/instagram.svg";
import linkedIn from "../../assets/svg/linkedln.svg";

class Footer extends Component {
  render() {
    return (
      <AUX>
        <section className="" id="">
          <div className="container-footer">
            <div className="row mt-5 pt-5 pb-2 footer">
              <div className="col-md-12">
                <div className="text-white footer-alt">
                  <div className="float-left">
                    <p className="copyright-desc pb-0">Dra. Diana Cruz</p>
                    <p className="copyright-sub">
                      Biologia de la Reproducción Humana
                    </p>
                    <p className="copyright-sub">
                      Ginecología y Obstetricia
                    </p>
                  </div>
                  <div className="float-right icons-foot">
                    <ul className="list-inline social pb-0">
                      <li className="list-inline-item pl-2">
                        <a 
                        href="https://www.facebook.com/FertilidadDianaCruzClavel" 
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                          <img src={facebook} alt="facebook-icon" />
                        </a>
                      </li>
                      <li className="list-inline-item pl-2">
                        <a 
                        href="https://www.linkedin.com/in/diana-elizabeth-cruz-clavel-197698138/?originalSubdomain=mx" 
                        target="_blank" 
                        rel="noopener noreferrer">
                          <img src={linkedIn} alt="instagram-icon" />
                        </a>
                      </li>
                      <li className="list-inline-item pl-2">
                        <a 
                        href=" https://www.instagram.com/ginecologadiana/ " 
                        target="_blank" 
                        rel="noopener noreferrer">
                          <img src={instagram} alt="linkedIn-icon" />
                        </a>
                      </li>
                      <li className="list-inline-item pl-2">
                        <a 
                        href="https://wa.me/5524987703" 
                        target="_blank" 
                        rel="noopener noreferrer">
                          <img src={WhatsIcon} alt="whatsapp-icon" />
                        </a>
                      </li>
                      <li className="list-inline-item pl-2">
                        <a 
                        href="https://goo.gl/maps/1Hu86vR2WZhN9rR18" 
                        target="_blank" 
                        rel="noopener noreferrer">
                          <img src={MapsIcon} alt="maps-icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AUX>
    );
  }
}

export default Footer;

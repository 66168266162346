import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import Header from "../Header/Header";
// import HeaderLight from "../Header/HeaderLight";

import Footer from "../Footer/Footer";

class Layout extends Component {
  render() {
    return (
      <AUX>
        {this.props.loginpage ? (
          this.props.children
        ) : (
          <main style={{ overflowX: "hidden" }}>
            <Header />
            {this.props.children}
            <Footer />
          </main>
        )}
      </AUX>
    );
  }
}

export default Layout;
